<script>
  /**
   * Renders a `<style>` tag without Vue messing with it.
   */
  export default {
    functional: true,
    render(createElement, context) {
      return createElement('style', context.data, context.children)
    },
  }
</script>
